import React from 'react';

import * as style from './TableRow.module.css';
import { Heading } from '../../../components';

const TableRow = ({ title, subtitle, text, price, priceSubtitle }) => {
  return (
    <div className={style['tableRow']}>
      <div>
        <Heading level="h6" highlight className={style['tableRow__title']}>
          {title}
        </Heading>
        {subtitle && (
          <span className={style['tableRow__subtitle']}>{subtitle}</span>
        )}
      </div>
      <div className={style['tableRow__text']}>{text}</div>
      <div>
        <p className={style['tableRow__price']}>{price}</p>
        {priceSubtitle && (
          <p className={style['tableRow__priceSubtitle']}>{priceSubtitle}</p>
        )}
      </div>
    </div>
  );
};

export default TableRow;
