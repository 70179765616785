import React from 'react';

import Layout from '../containers/Layout/Layout';
import { Container, Heading, Seo } from '../components';
import { TableRow } from '../containers/Services';
import { useTranslation } from '../hooks';

const Services = ({ pageContext: { currentPath } }) => {
  const { t, translations, locale } = useTranslation();

  return (
    <Layout>
      <Seo
        title={t('SEO.SERVICES.TITLE')}
        location={currentPath}
        lang={locale}
      />
      <Container tag="section">
        <Heading
          level="h2"
          visualLevel="h6"
          color="secondary"
          weight="bold"
          position="right"
        >
          {t('SERVICES.CASH_ONLY')}
        </Heading>
        {translations.SERVICES.ROWS.map((service, index) => (
          <TableRow
            key={index}
            title={service.TITLE}
            subtitle={service.SUBTITLE}
            text={service.TEXT}
            price={service.PRICE}
            priceSubtitle={service.PRICE_SUBTITLE}
          />
        ))}
      </Container>
    </Layout>
  );
};

export default Services;
